<template>
  <div class="mini_timeline"
       :class="{
          'mini_day_off': !dayData.is_w_d && !dayData.is_holiday, 
       }"
  >
    <div class="mini_day_of_month">
      {{ dayOfMonth }}
    </div>
    <div class="mini_act_work_time"
         :class="{
            'mini_act_w_norm_completed': isActVisible && isShiftNormCompleted && dayData.is_w_d,
            'mini_act_w_norm_not_completed': isActVisible && !isShiftNormCompleted && dayData.is_w_d,
            'mini_act_w_without_schedule' : !isSchVisible && isActVisible,
            'mini_act_w_is_holiday': dayData.is_holiday || isSchVisible && dayData.is_holiday
         }"
    >
      {{ total_act_w_common }}
    </div>
  </div>
</template>

<script>

export default {
  name: "mini-staff-calendar-day",
  props: {
    dayData: {
      type: Object,
    }
  },
  methods: {
    parseTimeFromDecimalToHm(timeInDecimalHm) {
      if (timeInDecimalHm) {
        const hours = Math.floor(timeInDecimalHm);
        const minutesDecimal = (timeInDecimalHm - hours) * 60;
        const minutes = Math.round(minutesDecimal);
        return minutes === 0 ? `${hours}` : `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
      } else {
        return ''
      }
    },
    ActVisible(dayData) {
      const {
        start_act_w_d,
        end_act_w_d,
        start_act_w_n,
        end_act_w_n
      } = dayData;
      return start_act_w_d && end_act_w_d || start_act_w_n && end_act_w_n
    },
    SchVisible(dayData) {
      const {
        start_sch_w_n,
        end_sch_w_n,
        start_sch_w_d,
        end_sch_w_d
      } = dayData;
      return start_sch_w_n && end_sch_w_n || start_sch_w_d && end_sch_w_d;
    },
  },
  computed: {
    isShiftNormCompleted() {
      if (this.isActVisible && this.isSchVisible) {
        const totalActW = Number(this.dayData.total_act_w_d) + Number(this.dayData.total_act_w_n);
        const totalSchW = Number(this.dayData.total_sch_w_d) + Number(this.dayData.total_act_w_n);
        return totalActW >= totalSchW;
      }
    },
    total_act_w_common() {
      if (this.isActVisible) {
        return this.parseTimeFromDecimalToHm(Number(this.dayData.total_act_w_d) + Number(this.dayData.total_act_w_n));

      }
    },
    dayOfMonth() {
      const date = new Date(this.dayData.date);
      return date.getDate();
    },
    isActVisible() {
      return this.ActVisible(this.dayData);
    },
    isSchVisible() {
      return this.SchVisible(this.dayData);
    },
  }
};
</script>
<style lang="css">
.mini_timeline {
  height: 100%;
  width: 100%;
  border-radius: 0.3rem;
  overflow: hidden;
  border: 1px solid #ececec;
}

.mini_day_of_month {
  font-size: 2.8vw;
  font-weight: 900;
  position: absolute;
  right: 1.8vw;
  top: 1.3vw;
  z-index: 100;
}

.mini_act_w_is_holiday {
  background: #f8d7e6;
}

.mini_act_w_norm_not_completed {
  color: #ea913b;
  background: #f7d7b6;
}

.mini_act_w_without_schedule {
  color: #8676f1 !important;
  background: #d2d7fa !important;
}

.mini_act_w_norm_completed {
  color: #6aad45;
  background: #d0e6c3;
}

.mini_day_off {
  background: #ececec;
}

.mini_act_work_time {
  display: flex;
  font-size: 3.6vw;
  font-weight: 900;
  height: 100%;
  align-items: center;
  justify-content: center;
}

</style>
// super color #5A425C;



